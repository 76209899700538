import * as React from "react"
import {Nav} from "react-bootstrap";
import {Grid} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby"
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import "./../styles/main_menu.scss";
import "./../styles/general.scss";
import {useRef, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import OpenChat from "./OpenChat";
import {renderJsonLdOrganization} from "../lib/ldjson";
import '../styles/announcemant.scss'

const MainMenu = (props) => {

    const { pageName, prismicContact } = props
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const ref = useRef(null);

    const menuElements = [
        {
            'text': "Acasa",
            'route': "/",
            "pageName": 'index'
        },
        {
            'text': "Despre mine",
            'route': "/aboutme/",
            "pageName": 'aboutme'
        },
        {
            'text': "Blog",
            'route': "/blog/",
            "pageName": 'blog'
        },
        {
            'text': "Servicii si preturi",
            'route': "/services/",
            "pageName": 'servicii_preturi'
        },
        {
            'text': "Contact",
            'route': "/contact/",
            "pageName": 'contact'
        },
        {
            'text': "Legislatie",
            'route': "/legal/",
            "pageName": 'legislatie'
        }
    ]

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>

                {menuElements.map((menuElement, mk) => {
                    return (
                        <ListItem key={mk}>
                            <Link to={menuElement.route} className={pageName === menuElement.pageName ? 'selected_menu_item': ''}>{menuElement.text}</Link>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    );

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpenMenu(open)
    };

    return (
        <React.Fragment>
            <Drawer
                anchor={"left"}
                open={isOpenMenu}
                onClose={toggleDrawer(false)}
                // classes={'drawer_extend'}
                onClick={toggleDrawer(!isOpenMenu)}
            >
                {list("left")}
            </Drawer>

            <Grid item sm={12} xl={12} lg={12} md={12} className={`header_menu w-full  menu-desktop-sticky main_menu_tablet_phone main_menu_container`}>
                <div className={"w-full h-full flex flex-row justify-content-end align-middle align-items-center pr-8"}>
                    <MenuIcon onClick={toggleDrawer(true)}/>
                </div>
            </Grid>



            <Grid item sm={12} xl={12} lg={12} md={12} className={`header_menu w-full menu-desktop-sticky main_menu_desktop main_menu_container`} ref={ref}>
                <Grid container className={"w-full h-full flex flex-row justify-content-center align-middle align-items-center"}>
                    {/*<div className={'font_large_primary my_name'}>*/}
                    {/*    <h1><FontAwesomeIcon icon={faPerson} color={'#32a852'} className={"text_green_light_color font_icon"}/>&nbsp;&nbsp;{prismicContact.data.therapeut_name.text}</h1>*/}
                    {/*</div>*/}

                    <div>
                        <Nav className="justify-content-end pr-8" activeKey="/home" >
                            {menuElements.map((menuElement, mk) => {
                                return (
                                    <Nav.Item key={mk} active={'true'} className={"mr-8"}>
                                        <Link
                                            to={menuElement.route} className={pageName === menuElement.pageName ? 'selected_menu_item': ''}
                                        >
                                            {menuElement.text}
                                        </Link>
                                    </Nav.Item>
                                )
                            })}

                            <OpenChat />
                        </Nav>
                    </div>
                </Grid>
            </Grid>
            <script
                type="application/ld+json"
                nonce={"mjkljkl78988KHJKLKkhkljkldsjgjkjjhjhjbhijsvgkjhoirfujkk"}
                dangerouslySetInnerHTML={{ __html: JSON.stringify(renderJsonLdOrganization()) }}
            />
        </React.Fragment>
    )
}

export default  MainMenu
