import mixpanel from 'mixpanel-browser';
import {canTrack} from "../lib/tracking";

if (canTrack()) {
    mixpanel.init('9e0b5429cb384512430971b793e48c16', {debug: true});
}


let actions = {
    identify: (id) => {
        if (canTrack()) mixpanel.identify(id);
    },
    alias: (id) => {
        if (canTrack()) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (canTrack()) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (canTrack()) mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;
