import {isMobile} from "react-device-detect";
import * as React from "react";
import '../styles/general.scss';

function WhatsUpContact (props) {
    let {whatsupItem, showText, message} = props
    if (process.env.NODE_ENV !== 'production') {
        message = " ! Se lucreaza la aplicate, va rugam ignorati: " + message
    }

    const openInNewTag = (phone, message) => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${message}`;
        window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
    }

    return (
        <>
            {!isMobile && (
                <div
                    className={'track-whatsapp-desktop flex flex-row justify-items-start align-middle align-items-start text_blue_link_color'}
                    onClick={(ev) => openInNewTag(whatsupItem.whatsup_phone_number.text, message)}
                >
                        <div>
                            <img src={'/images/whatsapp.png'} className={'mr-4 mb-4 list_icon'} alt={"Whatsapp contact pentru programare Cabinet Psihologic, psihoterapeut, avize"}/>
                        </div>
                        {showText && (
                            <div className={"contact_link"}>
                                <b>{whatsupItem.whatsup_desktop_text.text}</b>
                            </div>
                        )}
                </div>
            )}

            {isMobile && (
                <div
                    className={'track-whatsapp-mobile flex flex-row justify-items-start align-middle align-items-start text_blue_link_color'}
                    onClick={(ev) => openInNewTag(whatsupItem.whatsup_phone_number.text, message)}
                >
                    <div>
                        <img src={'/images/whatsapp.png'} className={'mr-4 mb-4 list_icon'} alt={"Whatsapp contact pentru programare Cabinet Psihologic, psihoterapeut, avize"}/>
                    </div>
                    {showText && (
                        <div>
                            <b>{whatsupItem.whatsup_mobile_text.text}</b>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default WhatsUpContact