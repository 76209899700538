const COOKIE_DEVELOPER_NAVIGATION = "websitedeveloperjklsklu5940uksllkfkdlsfdssdd";

let computed = -1;
const isDeveloperNavigation = () => {
    if (computed === -1) {
        try {
            computed = window.document.cookie.indexOf(COOKIE_DEVELOPER_NAVIGATION) !== -1;
        } catch (err) {
            console.log("ERR reading cookies:", err)
            computed = false;
        }
    }

    return computed;
}

console.log("Is developer navigation: ", isDeveloperNavigation());

export const canTrack = () => {
    return process.env.NODE_ENV === 'production' && !isDeveloperNavigation();
}

console.log("Can track: ", canTrack());