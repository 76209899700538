import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({ seoData}) {
  seoData = seoData || {}
  const description = (seoData && seoData.description) ? seoData.description : "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6, aviz, sedinte terapie, clinica"
  const title = (seoData && seoData.title ) ? seoData.title : 'Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6'
  const image = (seoData && seoData.image ) ? seoData.image : 'https://www.cabinet-psiho.ro/images/bussiness_image.jpg'
  const url = (seoData && seoData.url ) ? seoData.url : 'https://www.cabinet-psiho.ro/'
  const lang = 'ro'
  const metas = (seoData && seoData.metas) ? seoData.metas: []
  const canonical = seoData.canonical || null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'og:image',
          content: image
        },
        {
            name: 'og:url',
            content: url
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `Roxana Simionescu`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        }
      ].concat(metas)}
    >
      {canonical && (
        <link rel="canonical" href={canonical} />
      )}

    </Helmet>
  )
}

Seo.defaultProps = {
  seoData: {},
}

Seo.propTypes = {
  seoData: PropTypes.object,
}



export default Seo
