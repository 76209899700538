import * as React from "react"
import "./../styles/index.scss"
import "react-datepicker/dist/react-datepicker.css";
import '../styles/contact.scss';
import '../styles/general.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMailBulk, faPhone, faMap, faClock} from "@fortawesome/free-solid-svg-icons";
import splitContactPrismic from "../lib/structure_data";
import WhatsUpContact from "./WhatsUpContact";

function ContactBox(props) {

    const {prismicContact} = props;
    const [showEmail, setShowEmail] = React.useState(false);

    const {
        phoneNumbers,
        emails,
        locations,
        whatsups,
        programs
    } = splitContactPrismic(prismicContact)

    const renderLocationJsonLd= (location) => {
        return (
            {
                "@context": {
                    "name": "http://schema.org/name",
                    "description": "http://schema.org/description",
                    "image": {
                        "@id": "http://schema.org/image",
                        "@type": "@id"
                    },
                    "geo": "http://schema.org/geo",
                    "latitude": {
                        "@id": "http://schema.org/latitude",
                        "@type": "xsd:float"
                    },
                    "longitude": {
                        "@id": "http://schema.org/longitude",
                        "@type": "xsd:float"
                    },
                    "xsd": "http://www.w3.org/2001/XMLSchema#"
                },
                "name": location.short_name.text,
                "description": location.address.text,
                "image": location.image_building.url,
                "geo": {
                    "latitude": `${location.latitude.text}`,
                    "longitude": `${location.longitude.text}`,
                }
            }
        )
    }
    return (
        <>
            <div className={"flex flex-row justify-content-start align-middle align-items-center mb-8"}>
                <div className={"font_size_l flex flex-col justify-items-center align-middle align-items-center mt-4"}>

                    <div className={"mt-6 mb-16 divider_footer"}></div>

                    <div className={"font_size_xl "}><b>Suna sau scrie acum</b></div>
                    {/* phone */}
                    <div className={"flex flex-row justify-items-start align-middle align-items-start mt-8"}>
                        {phoneNumbers.items.map((phoneData, pk) => {
                            if (phoneData.display === false) {
                                return <div key={pk}></div>
                            }
                            return (
                                <div className={"mr-8 text_blue_link_color"} key={pk}>
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        color={'#32a852'}
                                        className={"mr-4 list_icon"}
                                        title={phoneData.phone_number.text}
                                    />
                                    <a hrefLang="ro" href={'tel:' + phoneData.phone_number.text} className={"track-phone contact_link"}><b>{phoneData.phone_number.text}</b></a>
                                </div>
                            )
                        })}
                    </div>

                    {/* email */}
                    <div className={" flex flex-row justify-items-center align-middle align-items-center mt-8"}>
                        {emails.items.map((emalData, ek) => {
                            return (
                                    <div key={ek}>
                                        <FontAwesomeIcon
                                            icon={faMailBulk}
                                            color={'#32a852'}
                                            className={"mr-4 list_icon"}
                                            title={'email'}
                                        />
                                        <span className={"mr-4"}>Email:</span>
                                        {!showEmail && (
                                            <>
                                                <span>**************</span>
                                                <button className={"ml-4 track-email contact_link"} onClick={() => setShowEmail(!showEmail)}>
                                                    Vezi mailul
                                                </button>
                                            </>
                                        )}
                                        {showEmail && (
                                            <>
                                                <span>{emalData.email.text}</span>
                                                <button className={"ml-4 contact_link"} onClick={() => setShowEmail(!showEmail)}>
                                                    Ascunde mailul
                                                </button>
                                            </>
                                        )}

                                    </div>
                                )
                        })}
                    </div>

                    {/* whatsapp */}
                    <div className={" flex flex-col justify-items-center align-middle align-items-center mt-8 mb-4"} >
                        {whatsups.items.map((whatsupItem, wk) => {
                            return (
                               <WhatsUpContact whatsupItem={whatsupItem} showText={true} message={""} key={wk}/>
                            )
                        })}
                        <div className={"mr-8 flex flex-row justify-items-center align-items-center js-open-zoho-chat"}>
                            <div className={"mr-4"}>
                                <img src={'/images/zoho_chat.png'} className={'zoho_icon_contact'} alt="zoho chat"/>
                            </div>
                            <div className={"track-zoho-chat contact_link"}>Open Website Chat</div>
                        </div>
                    </div>

                    <div className={"mt-16 mb-16 divider_footer"}></div>

                    {/* program */}
                    <div className={" flex flex-col justify-items-center align-middle align-items-center"}>
                        <div className={"font_size_xl"}><b>Program</b></div>
                        <div className={" flex flex-row justify-items-start align-middle align-items-start mt-4"}>
                            <div>
                                <FontAwesomeIcon icon={faClock} color={'#32a852'} className={"mr-4 list_icon"} title={'progamare'}/>
                            </div>
                            <div>
                                <span>{programs.primary.program.text}</span>
                            </div>
                        </div>
                    </div>

                    <div className={"mt-16 mb-16 divider_footer"}></div>

                    <div className={"font_size_xl"}><b>Adrese</b></div>

                    {/* addresses */}
                    <div className={" flex flex-row justify-items-center align-middle align-items-center mt-4 map_item"}>
                        {locations.items.map((location, lk) => {
                            return (
                                <div key={lk} className={"map_chart_item"}>
                                    <div className={"mt-4 mb-4"}>
                                        <a className={"track-address mb-4 font_size_l"} hrefLang="ro" href={`http://www.google.com/maps/place/${location.latitude.text},${location.longitude.text}`} target={"_blank"} rel="noreferrer" >
                                            <FontAwesomeIcon icon={faMap} className={"mr-4 list_icon text_green_light_color"} title={'harta'} />
                                            <span className={'contact_link'}>{location.address.text}</span>
                                        </a>
                                        <script
                                            type="application/ld+json"
                                            nonce={"mjkljkl78988KHJKLKkhkljkldsjgjkjjhjhjbhijsvgkjhoirfujkk"}
                                            dangerouslySetInnerHTML={{ __html: JSON.stringify(renderLocationJsonLd(location)) }}
                                        />

                                    </div>

                                    <div className={"map_chart_item_iframe"}  dangerouslySetInnerHTML={{ __html: location.embeded_map.raw[0].text }}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactBox