import * as React from "react";
import '../styles/open_chat.scss';

function OpenChat(props) {

    return (
        <>
            <button
                className={"track-open-realtime-chat open_chat js-open-zoho-chat"}
                onClick={ev => {
                    if (typeof window != "undefined") {
                        window.openZohoChatProgramatically();
                    }
                }}
            >
                Programeaza
            </button>
        </>
    )
}

export default OpenChat;