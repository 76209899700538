import _ from 'lodash';

export const renderJsonLdOrganization = () => {
    return {
        "@context": "http://www.schema.org",
        "@type": "ProfessionalService",
        "name": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6",
        "url": "https://www.cabinet-psiho.ro",
        "logo": "https://www.cabinet-psiho.ro/images/favicon.png",
        "image": "https://www.cabinet-psiho.ro/images/bussiness_image.jpg",
        "description": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6 Specializata in clinica medicala, psihoza, sedinte de cuplu, dezvoltare personala",
        "keywords": "cabintet, pshihologic, psihoterapeut, clinica, aviz, medical, sector2, sector6, bucuresti, terapie",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Bucuresti, Bulevardul Timisoara, sector 6, nr. 30",
            "addressLocality": "Bucuresti",
            "addressCountry": "Romania"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "44.462430351035586",
            "longitude": "26.11644049406071",
            "address": {
                //@see https://schema.org/PostalAddress
                "@type": "PostalAddress ",
                "addressCountry": "RO",
                "addressLocality": "Bucuresti",
                // "addressRegion": "",
                // "postalCode": ""
                "streetAddress": "Bulevardul Timisoara, nr. 30",
                "availableLanguage": "ro",
                "email": "simionescu.roxana@gmail.com",
                "telephone": "+40771046549",
                "description": "Cabinet Psihologic Roxana Simionescu, sector 6, Bulevardul Timisoara, nr. 30",
                // "image": ""
            }
        },
        "hasMap": "https://www.google.com/maps/place/44.462430351035586,26.11644049406071",
        "openingHours": "Mo, Tu, We, Th, Fr 10:00-20:00 Sa 10:00-16:00",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+40771046549",
            "email": "simionescu.roxana@gmail.com"
        },
        "telephone": "+40771046549",
        "priceRange": "200-600 RON",
        "paymentAccepted": "Cash, Credit Card",
        //cod fiscal
        "taxID": "40690872"
    }
}

export const renderJsonLdProduct = (serviceCategory, testimonials) => {
    let price = serviceCategory.price.text
    if (price.indexOf("/") !== -1) {
        price = price.split("/")[0]
    }
    price = price.toLowerCase().replaceAll('ron', "");
    price = price.replaceAll('/', "");
    price = _.trim(price);

    if (price.indexOf("–") >= 0) {
        price = _.trim(price.replaceAll("-", " ").split(" ")[0])
    }

    let jsonld = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": serviceCategory.category_name.text.toLowerCase(),
        "image": "https://www.cabinet-psiho.ro/images/favicon.png?v=44444",
        "description": serviceCategory.category_name.text.toLowerCase(),
        "mpn": "925872",
        "brand": {
            "@type": "Brand",
            "name": "Roxana Simonescu Psihoterapeut",
            "description": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6"
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": "RON",
            "availability": "https://schema.org/InStock",
            "price": price,
            "url": "https://www.cabinet-psiho.ro/services/",
            "priceValidUntil": "2030-01-01"
        },
        "sku": "sku-" + serviceCategory.sku.text.toLowerCase(),
        "aggregateRating": {
            "@type": "AggregateRating",
            "itemReviewed": {
                "@type": "Thing",
                "name": serviceCategory.category_name.text.toLowerCase(),
            },
            "reviewCount": testimonials.edges.length,
            "ratingValue": 5
        },
        "review": []
    }

    testimonials.edges.map(el => {
        jsonld["review"].push({
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": el.node.data.author_name.text
            },
            "datePublished": el.node.first_publication_date,
            "reviewBody": el.node.data.testimonial_content.text,
            "name": "Review - " + el.node.data.author_name.text,
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
            }
        })
    })

    return jsonld
}