const splitContactPrismic = (prismicContact) => {
    let phoneNumbers = []
    let emails = []
    let locations = []
    let whatsups = []
    let programs = {};

    prismicContact.data.body.map(bodySlice => {
        if (bodySlice.slice_type === "phone") {
            phoneNumbers = bodySlice
        } else if (bodySlice.slice_type === "whasup") {
            whatsups = bodySlice
        } else if (bodySlice.slice_type=== "location") {
            locations = bodySlice
        } else if (bodySlice.slice_type=== "email") {
            emails = bodySlice
        } else if (bodySlice.slice_type === "program") {
            programs = bodySlice
        }
    });

    return {
        phoneNumbers,
        emails,
        locations,
        whatsups,
        programs
    }
}

export const splitBlogArticlePrismic  = (blohArticlePrismic) => {
    let legislationItems = [];
    blohArticlePrismic.data.body.map(sliceItem => {
        if (sliceItem.slice_type === "link_legislatie") {
            legislationItems = sliceItem
        }
    })

    return {legislationItems}
}

export const splitAboutMePrismic  = (abautMePrismic) => {
    let acreditationsItems = [];
    let professionalFormationsItems =[];
    let psihoBaseFormationsItems = [];
    let otherPsihoterapeutForamtionsItems = [];
    let coupleFamilyItems = [];
    let autistItems = [];
    let otherItems = [];

    abautMePrismic.body.map(bodySlice => {
        if (bodySlice.slice_type === "acreditari") {
            acreditationsItems = bodySlice
        } else if (bodySlice.slice_type === "inregistrari_profesionale") {
            professionalFormationsItems = bodySlice
        } else if (bodySlice.slice_type === "formare_de_baza_ca_psiholog") {
            psihoBaseFormationsItems = bodySlice
        } else if (bodySlice.slice_type === "formare_de_baza_cuplu_familie") {
            coupleFamilyItems = bodySlice
        } else if (bodySlice.slice_type === "formare_de_baza_pshioterapeut_autist") {
            autistItems = bodySlice
        } else if (bodySlice.slice_type === "alte_formari_psihoterapeut") {
            otherPsihoterapeutForamtionsItems = bodySlice
        } else if (bodySlice.slice_type === "alte_formari_profesionale") {
            otherItems = bodySlice
        } else if (bodySlice.slice_type === "acreditari") {
            acreditationsItems = bodySlice
        }
    })

    return {
        acreditationsItems,
        professionalFormationsItems,
        psihoBaseFormationsItems,
        otherPsihoterapeutForamtionsItems,
        coupleFamilyItems,
        autistItems,
        otherItems
    }

}
export default  splitContactPrismic;
