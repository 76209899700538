import {Grid} from "@material-ui/core";
import * as React from "react";
import '../styles/footer.scss'
import ContactBox from "./ContactBox";
import '../styles/general.scss';

const AppFooter = (props) => {
    const {prismicContact} = props;

    return (
        <Grid item sm={12} xl={12} lg={12} md={12} className={`flex flex-row justify-content-around align-middle align-items-center mt-128 flex-wrap footer_box`}>
            <div className={"flex flex-column mb-8 container_box"}>
                <div className={"font-bold font_size_xxl"}>Roxana-Anca</div>
                <div className={"font_size_xl"}>
                    Psihologie, Psihoterapie, Dezvoltare Personala
                </div>

                <div className={"font_size_sm"}>
                    <a hrefLang="ro" href="/politicaconfidentialitate/">Copyright © 2022: Simonescu Roxana. | Politica de Confidentialitate & Cookies</a>
                </div>
            </div>

            <div className={"flex flex-column mb-8 align-middle justify-items-start align-items-lg-start"}>
               <ContactBox prismicContact={prismicContact} />
            </div>
        </Grid>
    )
}

export default AppFooter
